import { Beer, Home, Hotel, Utensils } from "lucide-react"
import { useNavigate } from "react-router-dom"

import { useUserSite } from "@/features/auth/api/get-user-site"
import { useSiteStore } from "@/stores/use-site-store"

import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../ui/control"

export const CommandSite = () => {
  const navigate = useNavigate()

  const setActiveSite = useSiteStore((state) => state.setActiveSite)

  const { data: sitesData, isLoading } = useUserSite()

  const sites =
    sitesData?.data.map((site) => ({
      id: site.id,
      label: site.label,
      code: site.code,
      plan: "PT Lokatara",
    })) ?? []

  const handleSiteChange = (siteId: number) => {
    navigate(`/app`)
    setTimeout(() => {
      setActiveSite(siteId)
    }, 1000)
  }
  return (
    <CommandDialog open={true} hideClose>
      <CommandInput placeholder="Select site or search site..." />
      <CommandList>
        <CommandEmpty>
          {isLoading ? (
            <span>Loading sites...</span>
          ) : (
            <span>No sites found</span>
          )}
        </CommandEmpty>
        {sites.length > 0 && (
          <CommandGroup heading="Sites">
            {sites.map((site) => (
              <CommandItem
                key={site.id}
                onSelect={() => handleSiteChange(site.id)}
              >
                <div className="mr-2 flex size-4 items-center justify-center">
                  {site.code === "hahatora" ? (
                    <Beer className="size-4" />
                  ) : site.code === "limakitchen" ? (
                    <Utensils className="size-4" />
                  ) : site.code === "hotel_suite5" ? (
                    <Hotel className="size-4" />
                  ) : (
                    site.code === "suite5" && <Home className="size-4" />
                  )}
                </div>
                <span>{site.label}</span>
              </CommandItem>
            ))}
          </CommandGroup>
        )}
      </CommandList>
    </CommandDialog>
  )
}
