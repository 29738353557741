import { ChevronRight } from "lucide-react"
import { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"

import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from "@/components/sidebar/sidebar"
import { Collapsible, CollapsibleContent } from "@/components/ui/surfaces"
import { MenuAllowedPermissions, MenuIcon, MenuLink } from "@/data/menu"
import { useModulePermission } from "@/features/auth/api/get-module-permission"
import { useAuthorization } from "@/lib/authorization"
import { useSiteStore } from "@/stores/use-site-store"
import { SidebarNavItem } from "@/types/nav"
import { removeDynamicSegments } from "@/utils/url"

type PermissionMainMenu = {
  id: number
  label: string
  code: string
  site: {
    id: number
    label: string
    code: string
    url: string
    logo: string
  }
}

type GroupedPermissionMainMenus = Record<string, PermissionMainMenu[]>

// Helper untuk mengelompokkan module permissions berdasarkan prefix
const groupPermissionsByPrefix = (
  permissions: PermissionMainMenu[]
): GroupedPermissionMainMenus => {
  return permissions.reduce<GroupedPermissionMainMenus>((acc, permission) => {
    const prefix = permission.code.split(".")[0]
    if (!acc[prefix]) acc[prefix] = []
    acc[prefix].push(permission)
    return acc
  }, {})
}

export function NavMain({ items }: { items: SidebarNavItem[] }) {
  const { pathname } = useLocation()
  const siteId = useSiteStore((state) => state.activeSite) || 1
  const modulePermissions = useModulePermission({ siteId })
  const { checkAccess } = useAuthorization()

  // State untuk melacak menu aktif
  const [activeItem, setActiveItem] = useState<string | null>(null)

  // Kelompokkan module permissions berdasarkan prefix
  const groupedPermissions = groupPermissionsByPrefix(
    modulePermissions.data?.data || []
  )

  // Tentukan menu aktif berdasarkan rute saat ini
  useEffect(() => {
    // Saat halaman pertama kali dimuat, tentukan menu aktif berdasarkan pathname
    const determineActiveItem = () => {
      // Periksa items (menu statis)
      const currentActiveItem = items.find((item) =>
        item.items?.some((subItem) =>
          subItem.path.includes(removeDynamicSegments(pathname))
        )
      )
      if (currentActiveItem) {
        return currentActiveItem.title
      }

      // Periksa module permissions (menu dinamis)
      const activePrefix = Object.keys(groupedPermissions).find((prefix) =>
        groupedPermissions[prefix].some(
          (module) => MenuLink({ code: module.code }) === pathname
        )
      )
      return activePrefix || null
    }

    setActiveItem(determineActiveItem())
  }, [pathname, items, groupedPermissions])

  return (
    <SidebarGroup>
      <SidebarGroupLabel>Main</SidebarGroupLabel>
      <SidebarMenu>
        {/* Static Items */}
        {items.map((item) =>
          item.items && item.items.length > 1
            ? // Menu dengan submenu
              checkAccess({
                allowedPermissions: item.allowedPermissions,
              }) && (
                <Collapsible
                  key={item.title}
                  asChild
                  open={activeItem === item.title}
                  onOpenChange={(isOpen) =>
                    setActiveItem(isOpen ? item.title : null)
                  }
                  className="group/collapsible"
                >
                  <SidebarMenuItem>
                    <SidebarMenuButton tooltip={item.title} asChild>
                      <Link to={item.href}>
                        {item.icon && <item.icon />}
                        <span>{item.title}</span>
                        <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                      </Link>
                    </SidebarMenuButton>
                    <CollapsibleContent>
                      <SidebarMenuSub>
                        {item.items.map(
                          (subItem) =>
                            checkAccess({
                              allowedPermissions: subItem.allowedPermissions,
                            }) && (
                              <SidebarMenuSubItem key={subItem.title}>
                                <SidebarMenuSubButton
                                  asChild
                                  isActive={subItem.path.includes(
                                    removeDynamicSegments(pathname)
                                  )}
                                >
                                  <Link to={subItem.href}>
                                    <span>{subItem.title}</span>
                                  </Link>
                                </SidebarMenuSubButton>
                              </SidebarMenuSubItem>
                            )
                        )}
                      </SidebarMenuSub>
                    </CollapsibleContent>
                  </SidebarMenuItem>
                </Collapsible>
              )
            : // Menu tunggal
              checkAccess({
                allowedPermissions: item.allowedPermissions,
              }) && (
                <SidebarMenuItem key={item.title}>
                  <SidebarMenuButton
                    tooltip={item.title}
                    asChild
                    isActive={item.path.includes(
                      removeDynamicSegments(pathname)
                    )}
                  >
                    <Link to={item.href}>
                      {item.icon && <item.icon />}
                      <span>{item.title}</span>
                    </Link>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              )
        )}

        {/* Dynamic Module Permissions */}
        {Object.keys(groupedPermissions).map((prefix) =>
          groupedPermissions[prefix].length > 1
            ? checkAccess({
                allowedPermissions: MenuAllowedPermissions[prefix],
              }) && (
                // Menu dengan submenu
                <Collapsible
                  key={prefix}
                  asChild
                  open={activeItem === prefix}
                  onOpenChange={(isOpen) =>
                    setActiveItem(isOpen ? prefix : null)
                  }
                  className="group/collapsible"
                >
                  <SidebarMenuItem>
                    <SidebarMenuButton tooltip={prefix} asChild>
                      <Link
                        to={MenuLink({
                          code: groupedPermissions[prefix][0].code,
                        })}
                      >
                        <MenuIcon code={prefix} />
                        <span className="capitalize">{prefix}</span>
                        <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                      </Link>
                    </SidebarMenuButton>
                    <CollapsibleContent>
                      <SidebarMenuSub>
                        {groupedPermissions[prefix].map(
                          (module) =>
                            checkAccess({
                              allowedPermissions:
                                MenuAllowedPermissions[module.code],
                            }) && (
                              <SidebarMenuSubItem key={module.id}>
                                <SidebarMenuSubButton
                                  asChild
                                  isActive={
                                    MenuLink({ code: module.code }) === pathname
                                  }
                                >
                                  <Link to={MenuLink({ code: module.code })}>
                                    <span>
                                      {module.code === "newsletter_subs"
                                        ? "Subscriptions"
                                        : module.label}
                                    </span>
                                  </Link>
                                </SidebarMenuSubButton>
                              </SidebarMenuSubItem>
                            )
                        )}
                      </SidebarMenuSub>
                    </CollapsibleContent>
                  </SidebarMenuItem>
                </Collapsible>
              )
            : // Menu tunggal
              groupedPermissions[prefix].map(
                (module) =>
                  checkAccess({
                    allowedPermissions: MenuAllowedPermissions[module.code],
                  }) && (
                    <SidebarMenuItem key={module.id}>
                      <SidebarMenuButton
                        tooltip={module.label}
                        asChild
                        isActive={MenuLink({ code: module.code }) === pathname}
                      >
                        <Link to={MenuLink({ code: module.code })}>
                          <MenuIcon code={module.code} />
                          <span>
                            {module.code === "newsletter_subs"
                              ? "Subscriptions"
                              : module.label}
                          </span>
                        </Link>
                      </SidebarMenuButton>
                    </SidebarMenuItem>
                  )
              )
        )}
      </SidebarMenu>
    </SidebarGroup>
  )
}
