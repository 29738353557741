import { create } from "zustand"
import { persist } from "zustand/middleware"

type SiteStore = {
  activeSite: number | null
  setActiveSite: (siteId: number | null) => void
  initializeActiveSite: (defaultSiteId: number) => void
}

export const useSiteStore = create<SiteStore>()(
  persist(
    (set, get) => ({
      activeSite: null,
      setActiveSite: (siteId) => set({ activeSite: siteId }),
      initializeActiveSite: (defaultSiteId) => {
        if (get().activeSite === null) {
          set({ activeSite: defaultSiteId })
        }
      },
    }),
    {
      name: "activeSite",
    }
  )
)
