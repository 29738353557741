import { useTranslation } from "react-i18next"
import { useRouteError } from "react-router-dom"

import { ForbiddenPermission } from "@/components/errors/forbidden-permission"
import { ContentLayout } from "@/components/layouts"
import { Note } from "@/components/ui/statuses/note"

export type RouteError = {
  message: string
  status?: number
  statusText?: string
  response?: Response
}

export default function ErrorPage() {
  const error = useRouteError() as RouteError
  console.error(error)

  const { t } = useTranslation()

  return (
    <ContentLayout title={t("ErrorTitle")}>
      {error?.response?.status === 403 ? (
        <ForbiddenPermission />
      ) : (
        <Note>
          {t("ErrorDescription")} {error?.message}
        </Note>
      )}
    </ContentLayout>
  )
}
