import { QueryClient, useQueryClient } from "@tanstack/react-query"
import { useMemo } from "react"
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom"

import { LoadingLayout } from "@/components/layouts/loading-layout"
import { ProtectedRoute } from "@/lib/auth"

import { AppRoot } from "./routes/app/root"
import ErrorPage from "./routes/error"

const createRouter = (queryClient: QueryClient) =>
  createBrowserRouter(
    [
      {
        path: "/",
        hydrateFallbackElement: <LoadingLayout />,
        element: <Navigate to="/app" replace />,
      },
      {
        path: "/auth/login",
        errorElement: <ErrorPage />,
        lazy: async () => {
          const { LoginRoute } = await import("./routes/auth/login")
          return { Component: LoginRoute }
        },
      },
      {
        path: "/app",
        hydrateFallbackElement: <LoadingLayout />,
        errorElement: <ErrorPage />,
        element: (
          <ProtectedRoute>
            <AppRoot />
          </ProtectedRoute>
        ),
        children: [
          {
            hydrateFallbackElement: <LoadingLayout />,
            errorElement: <ErrorPage />,
            children: [
              {
                path: "",
                lazy: async () => {
                  const { DashboardRoute, DashboardLoader } = await import(
                    "./routes/app/dashboard"
                  )
                  return {
                    Component: DashboardRoute,
                    loader: DashboardLoader(queryClient),
                  }
                },
              },
              {
                path: "menu",
                lazy: async () => {
                  const { MenusRoute, MenuLoader } = await import(
                    "./routes/app/menus"
                  )
                  return {
                    Component: MenusRoute,
                    loader: MenuLoader(queryClient),
                  }
                },
              },
              {
                path: "category-menu",
                lazy: async () => {
                  const { CategoryMenuRoute, CategoryMenuLoader } =
                    await import("./routes/app/category-menus")
                  return {
                    Component: CategoryMenuRoute,
                    loader: CategoryMenuLoader(queryClient),
                  }
                },
              },
              {
                path: "site-content",
                lazy: async () => {
                  const { SitesContentRoute, SitesContentLoader } =
                    await import("./routes/app/sites-content")
                  return {
                    Component: SitesContentRoute,
                    loader: SitesContentLoader(queryClient),
                  }
                },
              },
              {
                path: "reservation",
                lazy: async () => {
                  const { ReservationsRoute, ReservationsLoader } =
                    await import("./routes/app/reservations")
                  return {
                    Component: ReservationsRoute,
                    loader: ReservationsLoader(queryClient),
                  }
                },
              },
              {
                path: "contact",
                lazy: async () => {
                  const { ContactsRoute, ContactsLoader } = await import(
                    "./routes/app/contacts"
                  )
                  return {
                    Component: ContactsRoute,
                    loader: ContactsLoader(queryClient),
                  }
                },
              },
              {
                path: "subscription",
                lazy: async () => {
                  const { SubscriptionsRoute, SubscriptionsLoader } =
                    await import("./routes/app/subscriptions")
                  return {
                    Component: SubscriptionsRoute,
                    loader: SubscriptionsLoader(queryClient),
                  }
                },
              },
              {
                path: "room-type",
                lazy: async () => {
                  const { RoomsTypeRoute, RoomTypeLoader } = await import(
                    "./routes/app/rooms-type"
                  )
                  return {
                    Component: RoomsTypeRoute,
                    loader: RoomTypeLoader(queryClient),
                  }
                },
              },
              {
                path: "room",
                lazy: async () => {
                  const { RoomsRoute, RoomLoader } = await import(
                    "./routes/app/rooms"
                  )
                  return {
                    Component: RoomsRoute,
                    loader: RoomLoader(queryClient),
                  }
                },
              },
              {
                path: "amenity",
                lazy: async () => {
                  const { AmenitiesRoute, AmenitiesLoader } = await import(
                    "./routes/app/amenities"
                  )
                  return {
                    Component: AmenitiesRoute,
                    loader: AmenitiesLoader(queryClient),
                  }
                },
              },
              {
                path: "home-slider",
                lazy: async () => {
                  const { HomeSliderRoute, HomeSlidersLoader } = await import(
                    "./routes/app/home-slider"
                  )
                  return {
                    Component: HomeSliderRoute,
                    loader: HomeSlidersLoader(queryClient),
                  }
                },
              },
              {
                path: "activity",
                lazy: async () => {
                  const { ActivitiesRoute, ActivitiesLoader } = await import(
                    "./routes/app/activities"
                  )
                  return {
                    Component: ActivitiesRoute,
                    loader: ActivitiesLoader(queryClient),
                  }
                },
              },
              {
                path: "facility",
                lazy: async () => {
                  const { FacilitiesRoute, FacilitiesLoader } = await import(
                    "./routes/app/facilities"
                  )
                  return {
                    Component: FacilitiesRoute,
                    loader: FacilitiesLoader(queryClient),
                  }
                },
              },
              {
                path: "offer",
                lazy: async () => {
                  const { OffersRoute, OffersLoader } = await import(
                    "./routes/app/offers"
                  )
                  return {
                    Component: OffersRoute,
                    loader: OffersLoader(queryClient),
                  }
                },
              },
              {
                path: "user-management",
                children: [
                  {
                    path: "",
                    lazy: async () => {
                      const { UsersRoute, UsersLoader } = await import(
                        "./routes/app/users"
                      )
                      return {
                        Component: UsersRoute,
                        loader: UsersLoader(queryClient),
                      }
                    },
                  },
                  {
                    path: "roles",
                    lazy: async () => {
                      const { RolesRoute, RolesLoader } = await import(
                        "./routes/app/roles/roles"
                      )
                      return {
                        Component: RolesRoute,
                        loader: RolesLoader(queryClient),
                      }
                    },
                  },
                  {
                    path: "roles/edit/:roleId",
                    lazy: async () => {
                      const { EditRoleRoute, EditRoleLoader } = await import(
                        "./routes/app/roles/edit-role"
                      )
                      return {
                        Component: EditRoleRoute,
                        loader: EditRoleLoader(queryClient),
                      }
                    },
                  },
                  {
                    path: "permissions",
                    lazy: async () => {
                      const { PermissionsRoute, PermissionsLoader } =
                        await import("./routes/app/permissions")
                      return {
                        Component: PermissionsRoute,
                        loader: PermissionsLoader(queryClient),
                      }
                    },
                  },
                ],
              },
              {
                path: "site-management",
                lazy: async () => {
                  const { SitesRoute, SitesLoader } = await import(
                    "./routes/app/sites"
                  )
                  return {
                    Component: SitesRoute,
                    loader: SitesLoader(queryClient),
                  }
                },
              },
            ],
          },
        ],
      },
      {
        path: "*",
        lazy: async () => {
          const { NotFoundRoute } = await import("./routes/not-found-error")
          return { Component: NotFoundRoute }
        },
      },
    ],
    {
      basename: "/",
      future: {
        v7_fetcherPersist: true,
        v7_normalizeFormMethod: true,
        v7_relativeSplatPath: true,
        v7_partialHydration: true,
      },
    }
  )

export const AppRouter = () => {
  const queryClient = useQueryClient()

  const router = useMemo(() => createRouter(queryClient), [queryClient])

  return (
    <RouterProvider
      future={{
        v7_startTransition: true,
      }}
      router={router}
    />
  )
}
