import * as Sentry from "@sentry/react"
import { Suspense } from "react"
import { useTranslation } from "react-i18next"
import { Outlet, ScrollRestoration, useLocation } from "react-router-dom"

import { DashboardLayout } from "@/components/layouts"
import { CommandSite } from "@/components/shared"
import { Spinner } from "@/components/ui/statuses"
import { useSiteStore } from "@/stores/use-site-store"

export const AppRoot = () => {
  const location = useLocation()
  const { t } = useTranslation()

  const activeSite = useSiteStore((state) => state.activeSite)
  return (
    <DashboardLayout>
      <Sentry.ErrorBoundary
        key={location.pathname}
        fallback={<div>{t("ErrorTitle")}</div>}
      >
        <Suspense
          fallback={
            <div className="flex size-full items-center justify-center">
              <Spinner size="xl" />
            </div>
          }
        >
          <ScrollRestoration />
          {activeSite ? <Outlet /> : <CommandSite />}
        </Suspense>
      </Sentry.ErrorBoundary>
    </DashboardLayout>
  )
}
