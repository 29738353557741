import { Beer, Utensils, ChevronsUpDown, Hotel, Home } from "lucide-react"
import { useNavigate } from "react-router-dom"

import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from "@/components/sidebar/sidebar"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/collections"
import { Sites } from "@/data/sites"
import { useSiteStore } from "@/stores/use-site-store"

import { Skeleton, Spinner } from "../ui/statuses"

export function Siteswitcher({ sites }: { sites: Sites[] }) {
  const { isMobile } = useSidebar()
  const navigate = useNavigate()

  const activeSite = useSiteStore((state) => state.activeSite)
  const setActiveSite = useSiteStore((state) => state.setActiveSite)

  const selectedSite = sites.find((site) => site?.id === activeSite) ?? null

  const handleSiteChange = (siteId: number) => {
    navigate(`/app`)
    setTimeout(() => {
      setActiveSite(siteId)
    }, 1000)
  }

  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            {!selectedSite ? (
              <div className="flex items-center gap-2 p-2">
                <Skeleton className="size-8 rounded-lg" />
                <div className="flex-1">
                  <Skeleton className="mb-1.5 h-3 w-36" />
                  <Skeleton className="h-3 w-28" />
                </div>
                <ChevronsUpDown className="ml-auto size-4" />
              </div>
            ) : (
              <SidebarMenuButton
                size="lg"
                className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
              >
                <div className="flex aspect-square size-8 items-center justify-center rounded-lg bg-sidebar-primary text-sidebar-primary-foreground">
                  {selectedSite?.code === "hahatora" ? (
                    <Beer className="size-4" />
                  ) : selectedSite?.code === "limakitchen" ? (
                    <Utensils className="size-4" />
                  ) : selectedSite?.code === "hotel_suite5" ? (
                    <Hotel className="size-4" />
                  ) : selectedSite?.code === "suite5" ? (
                    <Home className="size-4" />
                  ) : (
                    <Spinner className="size-4 text-foreground" />
                  )}
                </div>
                <div className="grid flex-1 text-left text-sm leading-tight">
                  <span className="truncate font-semibold">
                    {selectedSite?.label ?? ""}
                  </span>
                  <span className="truncate text-xs">
                    {selectedSite?.plan ?? ""}
                  </span>
                </div>
                <ChevronsUpDown className="ml-auto" />
              </SidebarMenuButton>
            )}
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
            align="start"
            side={isMobile ? "bottom" : "right"}
            sideOffset={4}
          >
            <DropdownMenuLabel className="text-xs text-muted-foreground">
              Sites
            </DropdownMenuLabel>
            {sites.map((site) => (
              <DropdownMenuItem
                key={site.id}
                onClick={() => handleSiteChange(site.id)}
                className="gap-2 p-2"
              >
                <div className="flex size-6 items-center justify-center rounded-sm border">
                  {site.code === "hahatora" ? (
                    <Beer className="size-4" />
                  ) : site.code === "limakitchen" ? (
                    <Utensils className="size-4" />
                  ) : site.code === "hotel_suite5" ? (
                    <Hotel className="size-4" />
                  ) : site.code === "suite5" ? (
                    <Home className="size-4" />
                  ) : (
                    <Spinner className="size-4 text-foreground" />
                  )}
                </div>
                {site.label}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  )
}
