import { ChevronRight } from "lucide-react"
import { useState, useEffect } from "react"
import { Link, useLocation } from "react-router-dom"

import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from "@/components/sidebar/sidebar"
import { Collapsible, CollapsibleContent } from "@/components/ui/surfaces"
import { useAuthorization } from "@/lib/authorization"
import { SidebarNavItem } from "@/types/nav"
import { removeDynamicSegments } from "@/utils/url"

export function NavSettings({ items }: { items: SidebarNavItem[] }) {
  const { pathname } = useLocation()
  const { checkAccess } = useAuthorization()

  // State untuk melacak menu aktif
  const [activeItem, setActiveItem] = useState<string | null>(null)

  // Tentukan menu aktif berdasarkan rute saat ini ketika komponen pertama kali dimuat
  useEffect(() => {
    const currentActiveItem = items.find((item) =>
      item.items?.some((subItem) =>
        subItem.path.includes(removeDynamicSegments(pathname))
      )
    )
    if (currentActiveItem) {
      setActiveItem(currentActiveItem.title)
    } else {
      setActiveItem(null)
    }
  }, [pathname, items])

  return (
    <SidebarGroup>
      {items.some((item) =>
        checkAccess({
          allowedPermissions: item.allowedPermissions,
        })
      ) && <SidebarGroupLabel>Settings</SidebarGroupLabel>}
      <SidebarMenu>
        {items.map((item) =>
          item.items && item.items.length > 1
            ? checkAccess({
                allowedPermissions: item.allowedPermissions,
              }) && (
                <Collapsible
                  key={item.title}
                  asChild
                  open={activeItem === item.title}
                  onOpenChange={(isOpen) =>
                    setActiveItem(isOpen ? item.title : null)
                  }
                  className="group/collapsible"
                >
                  <SidebarMenuItem>
                    <SidebarMenuButton tooltip={item.title} asChild>
                      <Link to={item.href}>
                        {item.icon && <item.icon />}
                        <span>{item.title}</span>
                        <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                      </Link>
                    </SidebarMenuButton>
                    <CollapsibleContent>
                      <SidebarMenuSub>
                        {item.items.map(
                          (subItem) =>
                            checkAccess({
                              allowedPermissions: subItem.allowedPermissions,
                            }) && (
                              <SidebarMenuSubItem key={subItem.title}>
                                <SidebarMenuSubButton
                                  asChild
                                  isActive={subItem.path.includes(
                                    removeDynamicSegments(pathname)
                                  )}
                                >
                                  <Link to={subItem.href}>
                                    <span>{subItem.title}</span>
                                  </Link>
                                </SidebarMenuSubButton>
                              </SidebarMenuSubItem>
                            )
                        )}
                      </SidebarMenuSub>
                    </CollapsibleContent>
                  </SidebarMenuItem>
                </Collapsible>
              )
            : checkAccess({
                allowedPermissions: item.allowedPermissions,
              }) && (
                <SidebarMenuItem key={item.title}>
                  <SidebarMenuButton
                    tooltip={item.title}
                    asChild
                    isActive={item.path.includes(
                      removeDynamicSegments(pathname)
                    )}
                  >
                    <Link to={item.href}>
                      {item.icon && <item.icon />}
                      <span>{item.title}</span>
                    </Link>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              )
        )}
      </SidebarMenu>
    </SidebarGroup>
  )
}
