import * as React from "react"

import { NavMain } from "@/components/sidebar/nav-main"
import { NavUser } from "@/components/sidebar/nav-user"
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarRail,
} from "@/components/sidebar/sidebar"
import { navigation } from "@/data/menu"
import { useUserSite } from "@/features/auth/api/get-user-site"
import { useUser } from "@/lib/auth"

import { NavSettings } from "./nav-settings"
import { Siteswitcher } from "./site-switcher"

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  const navMain = navigation.filter((item) => item.section === "main")
  const navSettings = navigation.filter((item) => item.section === "settings")

  const { data: sitesData } = useUserSite()

  const sites =
    sitesData?.data.map((site) => ({
      id: site.id,
      label: site.label,
      code: site.code,
      plan: "PT Lokatara",
    })) ?? []

  const { data: user } = useUser()
  const dataUser = {
    name: user?.data.name ?? "",
    email: user?.data.email ?? "",
  }

  return (
    <Sidebar collapsible="icon" {...props}>
      <SidebarHeader>
        <Siteswitcher sites={sites} />
      </SidebarHeader>
      <SidebarContent>
        <NavMain items={navMain} />
        <NavSettings items={navSettings} />
      </SidebarContent>
      <SidebarFooter>
        <NavUser user={dataUser} />
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  )
}
