import { useTranslation } from "react-i18next"

import { Note } from "@/components/ui/statuses/note"

export const ForbiddenPermission = () => {
  const { t } = useTranslation()

  return (
    <Note>
      <h4 className="-mt-1 text-base font-bold">{t("ForbiddenTitle")}</h4>
      {t("ForbiddenDescription")}
    </Note>
  )
}
